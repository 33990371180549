@font-face {
    font-family: "KlaptBold";
    src: local("KlaptBold"),
    url("./fonts/Klapt/Klapt-Bold.otf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "KlaptRegular";
    src: local("KlaptRegular"),
    url("./fonts/Klapt/Klapt-Regular.otf") format("truetype");
    font-weight: normal;
}

#surveyElement {
    --font-family: "KlaptRegular","Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    --primary: #d91717;
    --answer-background-color: #0a0000;
    --speechify-primary-color : #d91717;
    --progress-buttons-color: #d04848;
    --background: #ffffff;
    --background-dim: #dadada;
    --background-dim-light: #c1c1c1;
    --primary-foreground: #000000;
    --foreground: #000000;
    --base-unit: 8px;

}